.retail {
    &__herospace {
        height: 380px;
        margin-bottom: 3em;

        .herospace__slide {
            height: 380px;
        }

        .herospace__slide__body {
            &:after {
                border-width-top: 380px;
            }

            &:before {
                height: 400px;
                right: -44px;
            }
        }

        .herospace__slide__image {
            img {
                height: 380px;
            }
        }
    }
}

.solutions {
    display: block;
    margin-bottom: 3em;

    ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
            width: 23%;
            height: 74px;
            margin: 1em 0;
            background: $color--gray-dark;
            display: flex;
            text-align: center;
            flex-direction: row;
            justify-items: center;
            align-items: center;

            h3 {
                width: 100%;
                font-size: 1.1em;
                font-weight: normal;
                line-height: 1.3;
                color: $color--blue-dark;
                text-transform: uppercase;
                text-align: center;

                span {
                    display: block;
                    text-transform: none;
                }
            }
        }
    }
}

@media #{$mq--tablet-portrait} {
    .retail {
        &__herospace {
            height: auto;
            flex-direction: column;

            .herospace__slide {
                height: auto;
            }

            .herospace__slide__body {
                width: 100%;
                flex-basis: 100%;

                &:after,
                &:before {
                    display: none;
                }
            }

            .herospace__slide__image {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .solutions {
        ul {
            li {
                width: 32%;
                margin: .5em 0;
            }
        }
    }
}

@media #{$mq--mobile-landscape} {
    .retail {
        &__herospace {
            .herospace__slide__image {
                display: block;
            }
        }
    }

    .solutions {
        ul {
            li {
                width: 49%;
            }
        }
    }
}

@media #{$mq--mobile-portrait} {
    .solutions {
        ul {
            li {
                width: 100%;
            }
        }
    }
}
