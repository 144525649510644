.btn {
    display: inline-block;
    font-family: $font--primary;
    font-size: 1em;
    font-weight: normal;
    line-height: 1em;
    background: none;
    border: none;
    border-radius: 2px;
    padding: 0 1em;
    color: $color--text;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    outline: none;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }

    &--default {
        position: relative;
        height: $height--button;
        line-height: $height--button - 1;
        background-color: $color--gray-dark;
        border-radius: 2px 0 2px 0;
        padding: 0 1em 0 2em;
        margin-right: 19px;

        &:after,
        &:before {
            content: '';
            width: 16px;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
        }

        &:after {
            right: -16px;
            @include triangle-ra(16px, $height--button, 'top-left', $color--gray-dark);
        }

        &:before {
            right: -19px;
            @include triangle-ra(16px, $height--button, 'bottom-right', $color--green);
        }

        &:hover {
            color: $color--text;
            background-color: lighten($color--gray-dark, 5%);

            &:after {
                border-top-color: lighten($color--gray-dark, 5%);
            }
        }

        &.is-selected {
            background: $color--green;
            color: $color--white;
            padding: 0 2em;
            margin-right: 0;

            &:after,
            &:before {
                display: none;
            }

            &:hover {
                background: $color--green;
                color: $color--white;
            }
        }
    }

    &--huge {
        width: 330px;
        height: 76px;
        line-height: 75px;
        font-size: 24px;
        padding-right: 1.5em;
        border: 0;
        background: url(../img/btn-offer.png) no-repeat;
        background-size: 100%;
        color: $color--white;
    }
}

@media #{$mq--desktop} {
    .btn {
        &--huge {
            width: 310px;
            height: 70px;
            line-height: 69px;
        }
    }
}

@media #{$mq--mobile-landscape} {
    .btn {
        &--huge {
            width: 280px;
            height: 68px;
            line-height: 67px;
        }
    }
}
