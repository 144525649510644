// Slightly lighten a color
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

// Slightly darken a color
@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}

// Standard gradient from top to bottom
@mixin linear-gradient($from-color, $to-color) {
    background-color: $from-color;
    background-image: -webkit-linear-gradient($from-color, $to-color);
    background-image: -moz-linear-gradient($from-color, $to-color);
    background-image: linear-gradient($from-color, $to-color);
}

// Three stop gradient with custom angle
@mixin custom-gradient($degree, $from-color, $mid-color, $to-color) {
    background-color: $from-color;
    background-image: -webkit-linear-gradient($degree, $from-color 0%, $mid-color 50%, $to-color 100%);
    background-image: -moz-linear-gradient($degree, $from-color 0%, $mid-color 50%, $to-color 100%);
    background-image: linear-gradient($degree, $from-color 0%, $mid-color 50%, $to-color 100%);
}

// Triangle
@mixin triangle($direction: 'down', $size: 20px, $color: #000) {
    width: 0;
    height: 0;
    border-bottom: $size solid #{set-triangle-color($direction, 'bottom', $color)};
    border-right: $size solid #{set-triangle-color($direction, 'right', $color)};
    border-left: $size solid #{set-triangle-color($direction, 'left', $color)};
    border-top: $size solid #{set-triangle-color($direction, 'top', $color)};
}

// Right-angled triangle
@mixin triangle-ra($width, $height, $direction, $color) {
    width: 0;
    height: 0;
    border-style: solid;

    @if $direction == bottom-left {
        border-color: transparent transparent transparent $color;
        border-width: $height 0 0 $width;
    }

    @if $direction == bottom-right {
        border-color: transparent transparent $color transparent;
        border-width: 0 0 $height $width;
    }

    @if $direction == top-left {
        border-color: $color transparent transparent transparent;
        border-width: $height $width 0 0;
    }

    @if $direction == top-right {
        border-color: transparent $color transparent transparent;
        border-width: 0 $width $height 0;
    }

    @if $direction == center {
        border-color: $color transparent transparent transparent;
        border-width: $height $width/2 0 $width/2;
    }
}
