body {
    background: $color--gray;
    font: normal $font--default-size $font--primary;
    color: $color--text;
    overflow-y: scroll;
    text-align: center;
}

.wrapper {
    width: $width--wrapper;
    position: relative;
    margin: 0 auto;
    text-align: left;
}

.site-content {
    min-height: 300px;
    overflow: hidden;
}

@media #{$mq--desktop} {
    .wrapper {
        width: 1000px;
    }
}

@media #{$mq--tablet-landscape} {
    .wrapper {
        width: 100%;
        padding: 0 1em;
    }
}
