.contact-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;

    &__data {
        flex-basis: 32%;
        margin-top: -1em;
        margin-right: 2em;

        dl {
            dt {
                position: relative;
                height: 45px;
                font-size: 1.2em;
                line-height: 45px;
                margin: .35em 0;
                padding-left: 55px;

                &:before {
                    content: '';
                    width: 45px;
                    height: 45px;
                    background: url(../img/contact-icons.png) $color--green;
                    background-size: 440%;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &:nth-child(1):before { background-position: 3px 5px; }
                &:nth-child(2):before { background-position: 162px 5px; }
                &:nth-child(3):before { background-position: 320px 4px; }
                &:nth-child(4):before { background-position: 279px 5px; }
                &:nth-child(5):before { background-position: 43px 5px; }
            }
        }
    }

    &__form {
        flex: 1;
    }
}

.contact-form {
    display: block;

    &__row {
        display: block;
        margin-bottom: 1em;

        &--half {
            display: flex;
            justify-content: space-between;

            .form-item {
                width: 49%;
            }
        }

        .form-item {
            label {
                display: none;
            }
        }
    }

    &__submit {
        float: right;
    }
}

.people-grid {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;

    &__item {
        width: $width--contact-avatar;

        &__image {
            width: $width--contact-avatar;
            height: $width--contact-avatar;
            display: flex;
            align-content: center;
            text-align: center;
            border: 4px solid $color--green-dark;
            border-radius: 50%;
            padding: 8px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        &__data {
            text-align: center;
            margin-top: .5em;

            h3 {
                font-size: 1.1em;
                font-weight: normal;
                line-height: 1.5;
                text-transform: uppercase;
                margin-bottom: .5em;
            }
        }
    }
}

@media #{$mq--tablet-landscape} {
    .contact-section {
        &__data {
            flex-basis: 38%;
        }
    }
}

@media #{$mq--tablet-portrait} {
    .contact-section {
        flex-direction: column;

        &__data {
            flex-basis: 100%;
            margin: 0 0 2em;
        }
    }

    .people-grid {
        flex-wrap: wrap;

        &__item {
            width: 50%;
            padding-bottom: 2em;

            &__image {
                width: 270px;
                height: 270px;
                margin: 0 auto;
            }
        }
    }
}

@media #{$mq--mobile-landscape} {
    .people-grid {
        &__item {
            &__image {
                width: 200px;
                height: 200px;
            }
        }
    }
}

@media #{$mq--mobile-portrait} {
    .contact-section {
        &__data {
            dl {
                dt {
                    font-size: 1em;
                }
            }
        }
    }

    .contact-form {
        &__row {
            &--half {
                flex-wrap: wrap;

                .form-item {
                    width: 100%;
                    margin-top: 1em;
                }
            }
        }
    }

    .people-grid {
        &__item {
            width: 100%;

            &__image {
                width: 240px;
                height: 240px;
            }
        }
    }
}
