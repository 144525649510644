.page-cols {
    display: flex;
    justify-content: space-between;

    article {
        width: 48%;
    }
}

@media #{$mq--mobile-landscape} {
    .page-cols {
        display: block;

        article {
            width: 100%;
            margin: 1em 0;
        }
    }
}
