.timeline {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    padding: 1em 300px;
    position: relative;

    &:before {
        content: '';
        width: 50%;
        height: 3px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: $color--green-dark;
    }

    &:after {
        content: '';
        width: 3px;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        background: $color--green-dark;
    }

    &__title {
        margin-bottom: 1em;

        &:after {
            display: none;
        }
    }

    &__item {
        width: 100%;
        position: relative;

        &:before {
            content: '';
            width: 80px;
            height: 3px;
            background: $color--green-dark;
            position: absolute;
            top: 17px;
            left: 50%;
        }
    }

    &__year {
        width: 75px;
        height: 36px;
        line-height: 36px;
        font-size: 24px;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 50;
        margin-left: -37px;
        background: $color--green-dark;
        color: $color--white;
        text-align: center;

        &:before {
            content: '';
            @include triangle-ra(11px, 36px, 'bottom-right', $color--green-dark);
            position: absolute;
            top: 0;
            left: -11px;
        }

        &:after {
            content: '';
            @include triangle-ra(11px, 36px, 'top-left', $color--green-dark);
            position: absolute;
            top: 0;
            right: -11px;
        }
    }

    &__body {
        h3 {
            font-size: 24px;
            font-weight: normal;
            line-height: 1.3;
            color: $color--green-dark;
            text-transform: uppercase;
        }

        div, p {
            font-size: 1.1em;
            line-height: 1.3;
        }
    }

    &__item {
        &:nth-of-type(odd) {
            text-align: left;
            padding: 3px 0 0 50%;

            &:before {
                margin-left: 0;
            }

            .timeline__body {
                padding-left: 100px;
            }
        }

        &:nth-of-type(even) {
            text-align: right;
            padding: 3px 50% 0 0;

            &:before {
                margin-left: -80px;
            }

            .timeline__body {
                padding-right: 100px;
            }
        }
    }
}

@media #{$mq--desktop} {
    .timeline {
        padding: 1em 100px;
    }
}

@media #{$mq--tablet-portrait} {
    .timeline {
        padding: 1em 10px;
    }
}

@media #{$mq--mobile-landscape} {
    .timeline {
        &:before,
        &:after {
            display: none;
        }

        &__year {
            left: 10px;
            margin-left: 0;
        }

        &__body {
            margin: -11px 0 0 110px;
        }

        &__item {
            text-align: left;

            &:before {
                display: none;
            }

            &:nth-of-type(odd) {
                text-align: left;
                padding: 1em 0;

                .timeline__body {
                    padding-left: 0;
                }
            }

            &:nth-of-type(even) {
                text-align: left;
                padding: 1em 0;

                .timeline__body {
                    padding-right: 0;
                }
            }
        }
    }
}

@media #{$mq--mobile-portrait} {
    .timeline {
        &__year {
            position: relative;
            margin-bottom: .25em;
        }

        &__body {
            margin: 0 0 .5em;
        }

        &__item {
            &:nth-of-type(odd) {
                padding-bottom: 0;
            }

            &:nth-of-type(even) {
                padding-bottom: 0;
            }
        }
    }
}
