.herospace {
    display: block;
    height: 310px;
    overflow: hidden;
    margin-bottom: 2em;

    &__gallery {
        width: 100%;
        height: 310px;
        display: block;

        .flickity-page-dots {
            bottom: 10px;
            left: 0;
            text-align: left;
            padding-left: 20px;

            .dot {
                width: 10px;
                height: 10px;
                background: $color--white;
                border: 2px solid transparent;
                opacity: 1;

                &.is-selected {
                    background: none;
                    border: 2px solid $color--white;
                }
            }
        }
    }

    &__slide {
        width: 100%;
        height: 310px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        &__body {
            position: relative;
            flex-basis: 390px;
            background: $color--green;
            color: $color--white;
            padding: 25px 0 10px 25px;

            &:after {
                content: '';
                @include triangle-ra(75px, 310px, 'top-left', $color--green);
                position: absolute;
                top: 0;
                right: -75px;
            }

            &:before {
                content: '';
                width: 15px;
                height: 330px;
                background: $color--gray;
                position: absolute;
                top: -9px;
                right: -52px;
                transform: rotate(13.5deg);
            }

            h2 {
                font-size: 22px;
                font-weight: normal;
                line-height: 1.3;
                margin-bottom: .35em;
                text-transform: uppercase;

                a {
                    color: $color--white;
                }
            }

            div, p, li {
                font-size: 15px;
                line-height: 1.3;
            }

            ul {
                margin-left: 1.5em;
                margin-bottom: .75em;
            }
        }

        &__image {
            flex: 1;

            img {
                width: auto;
                height: 310px;
            }
        }
    }
}

@media #{$mq--desktop} {
    .herospace {
        &__slide {
            &__body {
                flex-basis: 35%;
                width: 35%;
            }

            &__image {
                width: 65%;
            }
        }
    }
}

@media #{$mq--tablet-landscape} {
    .herospace {
        &__slide {
            &__body {
                flex-basis: 42%;
                width: 42%;
            }

            &__image {
                width: 58%;
            }
        }
    }
}

@media #{$mq--mobile-landscape} {
    .herospace {
        height: auto;

        &__gallery {
            height: auto;
        }

        &__slide {
            height: auto;

            &__body {
                flex-basis: 100%;
                width: 100%;
                padding-right: 25px;
                padding-bottom: 3em;
            }

            &__image {
                display: none;
            }
        }
    }
}
