.site-footer {
    display: block;
    background: $color--blue-dark;
    color: $color--white;

    .wrapper {
        text-align: center;
    }

    p {
        font-size: 1.1em;
        line-height: 1.5;
    }

    &__quote {
        display: block;
        background: $color--blue;
        padding: 45px 0 35px;

        p {
            margin-top: 1em;
        }
    }

    &__links {
        display: block;
        padding: 30px 0;

        p {
            font-style: italic;
            max-width: 80%;
            margin: 0 auto 1em;
        }

        ul {
            position: relative;
            display: block;
            margin-top: 2.5em;
            padding-top: 1em;

            &:before {
                content: '';
                width: 50%;
                height: 2px;
                background: $color--white;
                position: absolute;
                top: -1em;
                left: 25%;
            }

            li {
                display: inline-block;

                a {
                    color: $color--white;
                    font-size: 1.2em;
                    text-transform: lowercase;
                    margin: 0 1em;

                    &:after {
                        content: ' | ';
                        position: relative;
                        left: 1em;
                        display: inline-block;
                    }
                }

                &:last-of-type {
                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media #{$mq--mobile-landscape} {
    .site-footer {
        p {
            font-size: .95em;
        }

        &__quote {
            padding: 25px 0 15px;
        }

        &__links {
            padding: 15px 0;

            p {
                max-width: 95%;
            }

            ul {
                padding-bottom: 1em;

                li {
                    display: block;

                    a {
                        line-height: 2;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
