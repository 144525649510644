.form-item {
    input,
    textarea {
        width: 100%;
        height: $height--input;
        line-height: $height--input - 1;
        font-family: $font--primary;
        font-size: 1em;
        color: $color--text;
        border: 2px solid $color--blue-dark;
        border-radius: none;
        padding: 0 .5em;
        outline: none;
    }

    textarea {
        height: 170px;
        resize: none;
    }

    &.has-error {
        input, textarea {
            color: $color--danger;
            border-color: $color--danger;
        }
    }

    &.has-warning {
        input, textarea {
            color: $color--warning;
            border-color: $color--warning;
        }
    }

    &.has-success {
        input, textarea {
            color: $color--success;
            border-color: $color--success;
        }
    }
}
