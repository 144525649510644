.progress {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4em;

    &:after {
        content: '';
        width: 80%;
        height: 7px;
        position: absolute;
        top: 85px;
        left: 10%;
        z-index: 50;
        background: $color--green;
    }

    &__item {
        position: relative;
        min-width: 220px;
        padding: 0 1em;
        text-align: center;
        z-index: 70;

        &__icon {
            width: 140px;
            height: 140px;
            display: block;
            background: url(../img/progress-icons.png) no-repeat $color--green;
            background-size: 400%;
            border: 20px solid $color--gray;
            border-radius: 50%;
            margin: 1em auto 0;
        }

        &__body {
            h2 {
                font-size: 24px;
                font-weight: normal;
                color: $color--blue;
                text-transform: uppercase;
                white-space: nowrap;
                margin-bottom: .5em;
            }

            p {
                line-height: 1.5;

                &:last-child {
                    margin-top: .5em;
                }
            }
        }  

        &:nth-child(1) {
            .progress__item__icon { background-position: 3px 5px; }
        }

        &:nth-child(2) {
            .progress__item__icon { background-position: -85px 5px; }
        }

        &:nth-child(3) {
            .progress__item__icon { background-position: -195px 5px; }
        }

        &:nth-child(4) {
            .progress__item__icon { background-position: -296px 5px; }
        }
    }
}

@media #{$mq--tablet-landscape} {
    .progress {
        &__item {
            min-width: 190px;

            &__body {
                h2 {
                    font-size: 18px;
                }

                p {
                    font-size: .9em;
                }
            } 
        }
    }
}

@media #{$mq--tablet-portrait} {
    .progress {
        flex-direction: column;

        &:after {
            display: none;
        }

        &__item {
            width: 100%;
            min-width: 100%;
            text-align: left;
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin-bottom: 1em;

            &__icon {
                margin: -20px 0 0 0;
            }

            &__body {
                flex: 1;
                padding-top: 20px;

                h2 {
                    font-size: 1.3em;
                }

                p {
                    font-size: 1.1em;
                }
            }
        }
    }
}

@media #{$mq--mobile-portrait} {
    .progress {
        &__item {
            flex-direction: column;
            align-items: center;
            text-align: center;

            &__icon {
                margin: 0;
            }

            &__body {
                padding-top: 0;
            }
        }
    }
}
