@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&subset=latin-ext');

// Widths
$width--wrapper: 1170px;
$width--contact-avatar: 180px;

// Heights
$height--header: 120px;
$height--menu: 55px;
$height--tabs: 55px;
$height--input: 33px;
$height--button: 40px;

// Defaults colors
$color--black: #000;
$color--white: #fff;
$color--gray: #f6f6f6;
$color--gray-dark: #cbc9ca;

// Brand colors
$color--blue: #266d9b;
$color--blue-dark: #134c70;
$color--green: #8dc63f;
$color--green-dark: #79a628;
$color--text: #4e4e4e;

// Status colors
$color--success: #34bc66;
$color--warning: #df662c;
$color--danger: #c11d1d;

// Fonts
$font--primary: 'Roboto Condensed', Helvetica, Arial, sans-serif;
$font--default-size: 15px;
$font--default-color: $color--text;

// Media query breakpoints
$mq--desktop: 'screen and (max-width: 1320px)';
$mq--tablet-landscape: 'screen and (max-width: 1040px)';
$mq--tablet-portrait: 'screen and (max-width: 860px)';
$mq--mobile-landscape: 'screen and (max-width: 640px)';
$mq--mobile-portrait: 'screen and (max-width: 420px)';
