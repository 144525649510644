.about-services {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 1.5;
    color: $color--text;
    margin: 2em 0;

    &__title {
        position: relative;
        flex-basis: 200px;
        text-align: right;
        text-transform: uppercase;
        padding-right: 40px;

        &:after {
            content: '';
            width: 7px;
            height: 120%;
            background: $color--blue;
            position: absolute;
            top: -10%;
            right: 0;
            transform: rotate(17deg);
        }
    }

    &__body {
        flex: 1;
        font-weight: normal;
        padding-left: 40px;
    }
}

.featured {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;

    &__item {
        width: 32%;
        text-align: center;

        &__image {
            position: relative;
            width: 100%;
            height: 265px;
            overflow: hidden;

            a {
                position: relative;
                display: flex;
                align-content: center;
                justify-content: center;

                &:after {
                    content: '';
                    width: 26px;
                    height: 26px;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    z-index: 100;
                    background: url(../img/play.png) no-repeat;
                    background-size: 26px;
                }
            }

            img {
                width: auto;
                height: 265px;
            }

            &:before {
                content: '';
                width: 14px;
                height: 280px;
                background: $color--gray;
                position: absolute;
                top: -10px;
                right: 28px;
                z-index: 60;
                transform: rotate(13.5deg);
            }

            &:after {
                content: '';
                @include triangle-ra(62px, 265px, 'bottom-right', $color--green);
                position: absolute;
                top: 0;
                right: 0;
                z-index: 80;
            }
        }

        &__body {
            padding: 0 1em;

            h2 {
                font-size: 24px;
                font-weight: normal;
                color: $color--blue;
                line-height: 1.3;
                text-transform: uppercase;
                margin: .75em 0 .5em;

                a {
                    color: $color--blue;
                }
            }

            p {
                font-size: 1.1em;
                line-height: 1.5;
            }
        }
    }
}

@media #{$mq--desktop} {
    .about-services {
        font-size: 20px;
    }
}

@media #{$mq--tablet-portrait} {
    .featured {
        flex-direction: column;

        &__item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            text-align: left;
            margin-bottom: 1em;

            &__image {
                width: 300px;
                flex-basis: 300px;
            }

            &__body {
                flex: 1;
            }
        }
    }
}

@media #{$mq--mobile-landscape} {
    .about-services {
        flex-direction: column;
        margin-bottom: 3em;

        &__title {
            flex-basis: auto;
            padding-right: 0;
            font-size: 1.3em;
            color: $color--blue-dark;
            margin-bottom: .75em;

            &:after {
                display: none;
            }
        }

        &__body {
            padding-left: 0;
            text-align: center;
        }
    }

    .featured {
        &__item {
            &__image {
                width: 220px;
                flex-basis: 220px;
                height: 150px;

                img {
                    height: 150px;
                }
            }

            &__body {
                h2 {
                    margin-top: 0;
                }
            }
        }
    }
}

@media #{$mq--mobile-portrait} {
    .featured {
        &__item {
            flex-direction: column;

            &__image {
                width: 100%;
                flex-basis: auto;
                height: auto;
                margin-bottom: .5em;

                &:before,
                &:after {
                    display: none;
                }

                a {
                    display: block;

                    &:after {
                        display: none;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &__body {
                padding: 0 0 .5em;
            }
        }
    }
}
