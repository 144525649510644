.references {
    &__tabs {
        display: block;
        margin-bottom: 2em;

        ul {
            text-align: center;

            li {
                display: inline-block;

                a {
                    height: $height--tabs;
                    line-height: $height--tabs - 1;
                    margin-right: 30px;

                    &:after {
                        border-top-width: $height--tabs;
                    }

                    &:before {
                        border-bottom-width: $height--tabs;
                    }

                    &.is-selected {
                        margin-right: 11px;
                    }
                }

                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &__grid {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 2em;

        .grid-item {
            width: 33%;
            margin-bottom: 1em;

            &__image {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-bottom: 5px;

                img {
                    width: auto;
                    display: block;
                    height: 250px;
                    margin: 0 auto;
                }
            }

            &__title {
                display: block;
                background: $color--green;
                color: $color--white;
                text-align: center;
                padding: 1em;

                h2 {
                    font-size: 1.1em;
                    line-height: 1.7;
                    text-transform: uppercase;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

@media #{$mq--tablet-landscape} {
    .references {
        &__tabs {
            ul {
                li {
                    a {
                        height: $height--button;
                        line-height: $height--button - 1;
                        padding: 0 .5em;
                        border-radius: none;
                        margin-right: .5em;

                        &:after,
                        &:before {
                            display: none;
                        }

                        &.is-selected {
                            padding: 0 .5em;
                            margin-right: .5em;
                        }
                    }
                }
            }
        }
    }
}

@media #{$mq--tablet-portrait} {
    .references {
        &__grid {
            .grid-item {
                width: 49.5%;

                &__title {
                    padding: .5em;

                    h2 {
                        font-size: 1em;
                    }
                }

                &__image {
                    img {
                        height: 265px;
                    }
                }
            }
        }
    }
}

@media #{$mq--mobile-landscape} {
    .references {
        &__tabs {
            ul {
                li {
                    a {
                        height: auto;
                        line-height: 2;
                        margin: .25em;
                    }
                }
            }
        }

        &__grid {
            .grid-item {
                width: 100%;

                &__title {
                    padding: 1em;

                    h2 {
                        font-size: 1.2em;
                    }
                }

                &__image {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
