.site-header {
    display: block;
    height: $height--header;
    position: relative;
    z-index: 80;

    &__logo {
        width: 180px;
        height: 120px;
        display: block;
        position: relative;
        z-index: 50;
        background: url(../img/logo.png) center center no-repeat $color--gray;
        background-size: 140px;
    }

    &__navigation {
        width: 100%;
        height: $height--menu;
        background: $color--blue;
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 40;

        .wrapper {
            text-align: right;
        }

        ul {
            li {
                display: inline-block;

                a {
                    position: relative;
                    color: $color--white;
                    font-weight: normal;
                    font-size: 1.1em;
                    line-height: 55px;
                    text-transform: uppercase;
                    margin: 0 1em;

                    &:hover {
                        text-decoration: none;
                    }

                    &:after {
                        content: '';
                        width: 2px;
                        height: 38px;
                        position: absolute;
                        top: -8px;
                        right: -1.1em;
                        background: $color--white;
                        transform: rotate(15deg);
                    }

                    &.is-selected {
                        font-weight: bold;
                    }
                }

                &:last-of-type {
                    a {
                        margin-right: 0;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        &__trigger {
            display: none;
            float: right;
            font-size: 1.4em;
            color: $color--white;
            line-height: $height--menu;
            text-transform: uppercase;
        }
    }

    &__languages {
        position: absolute;
        top: 0;
        right: 0;
        height: 35px;
        line-height: 35px;

        a {
            display: inline-block;
            font-size: 1.2em;
            color: tint($color--black, 60%);

            &:first-child {
                &:after {
                    content: ' / ';
                }
            }

            &:hover {
                text-decoration: none;
            }

            &.is-selected {
                color: $color--blue;
            }
        }
    }
}

@media #{$mq--desktop} {
    .site-header {
        &__languages {
            right: 1em;
        }
    }
}

@media #{$mq--tablet-portrait} {
    .site-header {
        height: auto;

        &__navigation {
            ul {
                width: 100%;
                height: auto;
                position: absolute;
                top: 55px;
                right: 0;
                background: $color--blue-dark;
                display: none;

                li {
                    display: block;
                }
            }

            &__trigger {
                display: block;
            }

            &.is-open {
                ul {
                    display: block;

                    li {
                        a {
                            &:after {
                                display: none;
                            }
                        }

                        &:last-of-type {
                            a {
                                margin-right: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
}
