.page-body {
    display: block;
    color: $color--blue-dark;
    font-size: 1.1em;
    line-height: 1.7;
    margin-bottom: 3em;

    p, div {
        line-height: 1.7;
        margin-bottom: .75em;
    }

    h2, h3, h4, h5, h6 {
        font-weight: bold;
        line-height: 1.7;
        margin: .5em 0 .25em;
    }

    h2 {
        font-size: 1.7em;
    }

    h3 {
        font-size: 1.5em;
    }

    h4 {
        font-size: 1.3em;
    }

    h5 {
        font-size: 1.1em;
    }

    h6 {
        font-size: 1em;
    }

    ul, ol {
        margin-left: 2em;
    }

    table {
        border: 0;
        border-collapse: collapse;

        td {
            padding: .5em;
        }
    }
}
