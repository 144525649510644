.page-title {
    position: relative;
    display: block;
    text-align: center;
    margin: 1em 0 3em;

    &:after {
        content: '';
        width: 140px;
        height: 4px;
        background: $color--blue;
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -70px;
    }

    h1,
    span {
        font-size: 26px;
        font-weight: normal;
        line-height: 1.5;
        color: $color--blue-dark;
        text-transform: uppercase;
    }

    h2 {
        font-size: 16px;
        font-weight: normal;
        font-style: italic;
        line-height: 1.5;
        color: $color--green;
    }
}